import styled from "styled-components";

export const PlusButton = ( props ) => {
  const { children, onClickFaq } = props;
  return(
    <SPlusButton onClick={onClickFaq}>{children}</SPlusButton>
  );
}

const SPlusButton = styled.button`
  border: none;
  outline: none;
  background: none;
`;