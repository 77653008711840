import styled from "styled-components";
import TopPriceImg from "../../images/top-service-img.jpg";
import { MainButton } from "../button/MainButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { WhiteText } from "../text/WhiteText";
import { useHistory } from "react-router-dom";

export const TopPrice = () => {
  const history = useHistory();

  const onClickPrice = () => history.push("/price");
  return(
    <SPriceContainer>
      <SPriceBg>
        <SPriceBox>
          <SPriceContent>”お見積もり無料”</SPriceContent>
          <WhiteText>ComingSoon</WhiteText>
          <MainButton onClickLink={onClickPrice}>料金詳細はこちら<FontAwesomeIcon icon={faChevronRight} style={{marginLeft: "15px"}} /></MainButton>
        </SPriceBox>
      </SPriceBg>
    </SPriceContainer>
  );
};

const SPriceContainer = styled.div`
  height: 500px;
`;

const SPriceBox = styled.div`
  padding: 30px 0;
  text-align: center;
`;

const SPriceContent = styled.p`
  color: white;
  font-size: 30px;
  margin-bottom: 70px;
`;

const SPriceBg = styled.div`
  background: url(${TopPriceImg}) no-repeat center center fixed;
  height: 100%;
  padding: 100px 0;
`;

