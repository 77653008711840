import React from "react";
import styled from "styled-components";
import { Container } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { MainButton } from "../componets/button/MainButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";


export const Page404 = () => {
  const history = useHistory();

  const onClickHome = () => history.push("/")
  return(
    <Container>
      <ErrorPage>
        <h1>ページが見つかりません。</h1>
        <p>お探しのページは移動したか、削除された可能性があります。</p>
        <MainButton onClickLink={onClickHome}>サイトに戻る<FontAwesomeIcon icon={faChevronRight} style={{marginLeft: "15px"}} /></MainButton>
      </ErrorPage>
    </Container>
  );
}

const ErrorPage = styled.div`
  text-align: center;
  height: 100vh;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

