import React, {useState} from "react";
import styled from "styled-components";
import mediaQuery from "styled-media-query";
import { HamburgerMenu } from "./HamburgerMenu";

export const Hamburger = () => {
  const [isActive, setIsActive] = useState(false);

  const onClickOpen = () => {
    setIsActive(!isActive)
  };
  

  
  return(
    <HumburgerContainer>
      <BtnTrigger isActive={isActive} onClick={onClickOpen}>
        <span></span>
        <span></span>
        <span></span>
      </BtnTrigger>
      {isActive && <HamburgerMenu />}
    </HumburgerContainer>
  );
};


const HumburgerContainer = styled.div`
  display: none;
  ${mediaQuery.lessThan("medium")`
    display: block;
  `}
`;



const BtnTrigger = styled.div`
  position: fixed;
  top: 10px;
  right: 10px;
  z-index: 100;
  width: 50px;
  height: 44px;
  cursor: pointer;
  display: inline-block;
  transition: all .5s;
  box-sizing: border-box;
  span {
    position: absolute;
    left: 0;
    width: 100%;
    height: 4px;
    background-color: #333;
    border-radius: 4px;
    display: inline-block;
    transition: all .5s;
    box-sizing: border-box;
  }

  span:nth-of-type(1) {
    top: 0;
    transform: ${props => props.isActive ? "translateY(20px) rotate(-45deg)" : "none"};
    background-color: ${props => props.isActive && "#fff"};
  }

  span:nth-of-type(2) {
    top: 20px;
    opacity: ${props => props.isActive ? "0" : "1"}
  } 

  span:nth-of-type(3) {
    bottom: 0;
    transform: ${props => props.isActive ? "translateY(-20px) rotate(45deg)" : "none" };
    background-color: ${props => props.isActive && "#fff"};
  }
`;