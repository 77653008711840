import styled from "styled-components";
import { ChangeImage } from "../images/ChangeImage";
import Logo from "../../images/Logo.png";
import { Link } from "react-router-dom";
import mediaQuery from "styled-media-query";
import { Hamburger } from "./Hamburger";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMobileAlt } from "@fortawesome/free-solid-svg-icons";

export const Header = () => {
  const Menus = [
    {name: "地域対応", path: "/area"},
    {name: "料金詳細", path: "/price"},
    {name: "サービス", path: "/comingsoon"},
    {name: "お問い合わせ", path: "/contact"},
  ]
  return(
    <HeaderTop>
      <LogoLink to="/">
        <ChangeImage image={Logo} alt="logo" />
      </LogoLink>
      <HeaderMenu>
        {Menus.map((menu, index) => {
          return(
            <SLink key={index} to={menu.path}>
              <HeaderMenuItems >{menu.name}</HeaderMenuItems>
            </SLink>
          );
        })}
      </HeaderMenu>
      <HeaderContact>
        <HeaderTel href="tel:03-1234-5678"><SIcon><FontAwesomeIcon icon={faMobileAlt} /></SIcon>03-1234-5678</HeaderTel>
      </HeaderContact>
      <Hamburger />
    </HeaderTop>
  );
}

const HeaderTop = styled.header`
  width: 100%;
  margin: 0 auto;
  display: flex;
  justify-content: space-around;
  ${mediaQuery.lessThan("medium")`
    display: block;
  `}
`;

const LogoLink = styled(Link)`
  &:hover {
    opacity: 0.6;
  }
`;

const HeaderMenu = styled.ul`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin: 0;
  ${mediaQuery.lessThan("medium")`
    display: none;
  `}
`;

const HeaderContact = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  ${mediaQuery.lessThan("medium")`
    display: none;
  `}
`;

const HeaderTel = styled.a`
  font-size: 28px;
  padding: 15px 0 15px 52px;
  border-left: 2px solid gray;
  text-decoration: none;
  color: black;
  font-weight: bold;
`;

const SIcon = styled.span`
  color: #0071BC;
  margin-right: 10px;
  font-size: 22px;
`;

const SLink = styled(Link)`
  text-decoration: none;
  color: #333; 
  position: relative;
  font-weight: bold;
  &::after {
    position: absolute;
    bottom: -2px;
    left: 10px;
    content: '';
    width: 80%;
    height: 3px;
    background: #0071BC;
    transform: scale(0, 1);
    transform-origin: left top;
    transition: transform .3s;
  }
  &:hover::after {
    transform: scale(1, 1);
  }
`;

const HeaderMenuItems = styled.li`
  list-style: none;
  padding: 10px 18px;
`;