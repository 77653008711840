import styled from "styled-components";
import mediaQuery from "styled-media-query";

export const PageTitle = ( props ) => {
  const { image, title } = props;
  return(
    <STitleArea image={image}>
      <SBackground></SBackground>
      <STitle>{title}</STitle>
    </STitleArea>
  );
}

const STitleArea = styled.div`
  height: 350px;
  width:100%;
  background: url(${props => props.image}) no-repeat center center;
  position: relative;
`;

const SBackground = styled.div`
  background-color: black;
  opacity: .5;
  width: 100%;
  height: 100%;
`;

const STitle = styled.p`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
  -webkit-transform: translateY(-50%) translateX(-50%);
  margin: auto;
  text-align: center;
  color: white;
  font-size: 3em;
  letter-spacing: 6px;
  ${mediaQuery.lessThan("medium")`
    font-size: 30px;
  `}
`;