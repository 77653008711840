import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClock } from "@fortawesome/free-regular-svg-icons";
import { Link } from "react-router-dom";

export const Comingsoon = () => {
  return(
    <ComingBg>
      <FontAwesomeIcon icon={faClock} style={{ fontSize: "58px", color: "lightgray" }} />
      <ComingTitle>Coming Soon</ComingTitle>
      <ComingCont>現在ページの準備を進めております。<br />今しばらくお待ちくださいませ。</ComingCont>
      <Link to="/">Topに戻る</Link>
    </ComingBg>
  );
};

const ComingBg = styled.div`
  background-color: #faf0e6;
  height: 100vh;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const ComingTitle = styled.h1`
  color: #333;
  padding: 18px 0;
`;

const ComingCont = styled.p`
  color: gray;
  text-align: center;
`;

