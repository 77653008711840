import styled from "styled-components";

export const PriceRow = ( props ) => {
  const { floorPlan, m, value, isHeader } = props;
  return(
    <SPriceRow>
      <SPriceCol isHeader={isHeader}>{floorPlan}</SPriceCol>
      <SPriceCol isHeader={isHeader}>{m}</SPriceCol>
      <SPriceCol isHeader={isHeader}>{value}</SPriceCol>
    </SPriceRow>
  );
}

const SPriceRow = styled.div`
  display: flex;
  font-size: 1.2em;
  justify-content: space-around;
`;

const SPriceCol = styled.div`
  width: 33%;
  background-color: whitesmoke;
  padding: 20px 0px;
  text-align: center;
  margin-bottom: 3px;
`;