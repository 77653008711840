import styled from "styled-components";

export const Title = ( props ) => {
  const { title } = props;
  return(
    <STitle>
      <STitleName>{title}</STitleName>
      <Border></Border>
    </STitle>
  );
}

const STitle = styled.div`
  margin: 40px 0;
  padding: 5px 0;
  font-size: 24px;
  text-align: center;
`;

const STitleName = styled.h2`
  font-weight: bold;
  margin: 15px 0;
`;

const Border = styled.div`
  border-bottom: 2px solid #333;
  width:10%;
  margin: 0 auto;
`;