import styled from "styled-components";
import { BaseText } from "./BaseText";

export const Text = ( props ) => {
  const { children } = props;
  return(
    <SText>{children}</SText>
  );
};

const SText = styled(BaseText)`
  color: #333;
`;