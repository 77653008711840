import styled from "styled-components";
import { Col, Container } from "react-bootstrap";
import Camela from "../../images/sample-camela.jpeg";
import Hunger from "../../images/sample-hunger.jpeg";
import Bed from "../../images/sample-bed.jpeg";
import mediaQuery from "styled-media-query";


const Services = [
  {src: Camela, content: "ComingSoon"},
  {src: Hunger, content: "ComingSoon"},
  {src: Bed, content: "ComingSoon"},
]

export const TopService = () => {
  
  
  return(
    <Container>
      <SRow>
        {Services.map((service, index) => {
          return(
            <SCol key={index} >
              <SService>
                <img src={service.src} alt={service.content} />
                <SServiceCnt>{service.content}</SServiceCnt>
              </SService>
            </SCol>
          );
        })}
      </SRow>
    </Container>
  );
};

const SRow = styled.div`
  display: flex;
  ${mediaQuery.lessThan("medium")`
    display: block;
  `}
`;

const SCol = styled(Col)`
  text-align: center;
  border-right: 1px solid black;
  ${mediaQuery.lessThan("medium")`
    border: none;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  `}
`;

const SService = styled.div`
  text-align: center;
`;

const SServiceCnt = styled.p`
  color: #333;
  padding: 15px 0;
  font-size: 24px;
`;


