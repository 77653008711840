import styled from "styled-components";
import { BaseImage } from "./BaseImage";
import mediaQuery from "styled-media-query";
import TopOnImg from "../../images/top-on-img.jpg";
import { CoolButton } from "../button/CoolButton";

export const Image = ( props ) => {
  const { image } = props;
  return(
    <Img image={image}>
      <STopOnImg src={TopOnImg} alt="top-on-img" />
      <CoolButton />
    </Img>
  );
}

const Img = styled(BaseImage)`
  background-image: url(${props => props.image});
  background-repeat: no-repeat;
  background-size: cover;
  height: 800px;
  width: 100%;
  position: relative;
`;

const STopOnImg = styled.img`
  height: 500px;
  width:800px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
  -webkit-transform: translateY(-50%) translateX(-50%);
`;

const SWhiteText = styled.p`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
  -webkit-transform: translateY(-50%) translateX(-50%);
  margin: auto;
  text-align: center;
  color: white;
  font-size: 1.5em;
  font-family: "Noto Serif JP", "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", serif;
  letter-spacing: 6px;
  ${mediaQuery.lessThan("medium")`
    font-size: 22px;
  `}
`;