import styled from "styled-components";

export const Container = ( props ) => {
  const { children } = props;
  return(
    <SContainer>
      { children }
    </SContainer>
  );
}

const SContainer = styled.div`
  margin: 0 auto;
  width: 80%;
`;