import { Container, Card, CardGroup } from "react-bootstrap";
// import SampleImg from "../../images/gal.jpeg";
// import SampleImg2 from "../../images/gal2.jpeg";
// import SampleImg3 from "../../images/gal3.jpeg";
import SampleImg1 from "../../images/voice.jpeg";


export const TopVoice = () => {
  const voices = [
    {src: SampleImg1, name: "田中玲奈", content: "ComingSoon"},
    {src: SampleImg1, name: "佐藤麗華", content: "ComingSoon"},
    {src: SampleImg1, name: "鈴木麗美", content: "ComingSoon"},
  ]
  return(
    <Container>
      <CardGroup>
        {voices.map((voice, index) => (
          <Card key={index}>
          <Card.Img variant="top" src={voice.src}></Card.Img>
          <Card.Body>
            <Card.Title>{voice.name} 様</Card.Title>
            <Card.Text>{voice.content}</Card.Text>
          </Card.Body>
        </Card>
      ))}
      </CardGroup>
    </Container>
  );
};


