import React, { useState } from "react";
import styled from "styled-components";
import { Container } from "react-bootstrap";
import { PlusButton } from "../button/PlusButton";
import { MinusButton } from "../button/MinusButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { faMinus } from "@fortawesome/free-solid-svg-icons";


export const TopFaq = () => {
  const faqs = [
    {question: "ComingSoon", answer: "ComingSoon。"},
    {question: "ComingSoon", answer: "ComingSoon。"},
    {question: "ComingSoon", answer: "ComingSoon。"},
  ]

  const [ openList, setOpenList ] = useState({0: false, 1: false, 2:false});

  const onClickShow = (index) => {
    const newOpenList = {...openList}
    newOpenList[index] = true;
    setOpenList(newOpenList)
  };

  const onClickHide = (index) => {
    const newOpenList = {...openList}
    newOpenList[index] = false;
    setOpenList(newOpenList)
  };
  return(
    <FaqContainer>
      {faqs.map((faq, index) => {
        return(
          <div key={index}>
            <SQuestion>
              <h5><span>Q</span>{faq.question}</h5>
              {openList[index] ? <MinusButton onClickFaq={() => onClickHide(index)}><FontAwesomeIcon icon={faMinus} /></MinusButton> : <PlusButton onClickFaq={() => onClickShow(index)}><FontAwesomeIcon icon={faPlus} /></PlusButton>}
              
            </SQuestion>
            
            <SAnswer isActive={openList[index]}>
               <p><span>A</span>{faq.answer}</p>
            </SAnswer>
            
          </div>
        );
      })}
    </FaqContainer>
  );
};

const FaqContainer = styled(Container)`
  padding-bottom: 100px;
  background-color: #f8f8ff;
`;

const SQuestion = styled.div`
  padding: 20px 20px 10px 20px;
  width: 90%;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  span {
    font-size:30px;
    color: red;
    margin-right: 10px;
  }
`;

const SAnswer = styled.div`
  background-color: #f5deb3;
  padding: 0 18px;
  width: 90%;
  margin: 0 auto;
  height: ${props => props.isActive ? "50px" : "0px"};
  overflow: hidden;
  transition: 1s;
  span {
    color: red;
    font-size: 30px;
    margin-right: 10px;
  }
`;