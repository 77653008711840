import styled from "styled-components";
import { Layout } from "../layout/Layout";
import { PageTitle } from "../componets/title/PageTitle";
import TopImg from "../images/top-img.jpg";
import { Container } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLine } from "@fortawesome/free-brands-svg-icons";
import mediaQuery from "styled-media-query";



export const Contact = () => {


  return(
    <Layout>
      <PageTitle image={TopImg} title="お問い合わせ" />
      <ContactContainer>
        <Container>
          <h1 style={{ textAlign: "center" }}>お問い合わせページ</h1>
          <Flex>
            <Frame>
              <h4 style={{ margin: "25px" }}>LINEはこちら</h4>
              <Line href="https://lin.ee/DgBCqxb"><FontAwesomeIcon icon={faLine} style={{ marginRight: "10px" }} />LINE無料見積</Line>
            </Frame>
            <Frame>
              <h4>お急ぎの方はこちら</h4>
            </Frame>
          </Flex>
        </Container>
      </ContactContainer>
    </Layout>
  );
}

const ContactContainer = styled.div`
  background-color: #fff0f5;
  padding: 50px 0;
`;

const Flex = styled.div`
  display: flex;
  justify-content: space-around;
  padding: 20px 10px;
  ${mediaQuery.lessThan("medium")`
    justify-content: center;
    flex-wrap: wrap;
    flex-direction: column;
  `}
`;

const Line = styled.a`
  display: block;
  text-align: center;
  background-color: #00B900;
  padding: 15px 20px;
  color: white;
  text-decoration: none;
  border-radius: 10px;
  margin: 25px 0;
`;

const Frame = styled.div`
  border: 1px solid gray;
  padding: 18px 24px;
  border-radius: 10px;
`;