import styled from "styled-components";
import { Container, Row, Col } from "react-bootstrap";
import { ChangeImage } from "../images/ChangeImage";
import Logo from "../../images/Logo.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInstagram, faTwitter } from "@fortawesome/free-brands-svg-icons";
import { faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import { FooterContact } from "./FooterContact";

export const Footer = () => {
  const getDate = new Date();

  const Year = getDate.getFullYear();

  const Menus = [
    {name: "トップ", link: "/"},
    {name: "対応地域", link: "/area"},
    {name: "料金詳細", link: "/price"},
    {name: "サービス", link: "/comingsoon"},
    {name: "お問い合わせ", link: "/contact"},
  ]
  return(
    <FooterContainer>
      <Container>
        <Row>
          <Col sm={3}>
            <div>
              <ChangeImage image={Logo} alt="Logo" />
              <FooterCompany>営業時間(平日9:00〜18:00)<br />お気軽にお問い合わせください。</FooterCompany>
              <p style={{ color: "#fff" }}>Follow us</p>
              <div>
                <Link to=""><FontAwesomeIcon icon={faInstagram} style={{ color: "orange", fontSize: "16px", marginRight: "10px" }} /></Link>
                <Link to=""><FontAwesomeIcon icon={faTwitter} style={{ color: "orange", fontSize: "16px", marginRight: "10px" }} /></Link>
              </div>
            </div>
          </Col>
          <Col sm={9} className="mt-4 py-2">
            <div>
              <h5 style={{ color: "#fff", borderBottom: "1px solid #fff", width: "50%" }}>QUICK LINKS</h5>
              <FooterMenu>
                {Menus.map((menu, index) => {
                  return(
                    <FooterMenuLink to={menu.link} key={index}>
                      <FooterMenuList><FontAwesomeIcon icon={faAngleRight} style={{ marginRight: "10px"}} />{menu.name}</FooterMenuList>
                    </FooterMenuLink>
                  );
                })}
              </FooterMenu>
            </div>
          </Col>
        </Row>
        <div>
          <FooterCopy>&copy;{Year}. AirSupply ALL RIGHTS RESERVED</FooterCopy>
        </div>
      </Container>
      <FooterContact />
    </FooterContainer>
    
  );
}


const FooterContainer = styled.footer`
  background-color: #000;
  padding: 80px 0 10px 0;
  position: relative;
`;

const FooterCompany = styled.p`
  color:#fff;
`;

const FooterCopy = styled.p`
  font-size: 12px;
  color: #fff;
`;

const FooterMenu = styled.ul`
  margin: 40px 0 ;
  line-height: 2;
  padding-left: 0;
`;

const FooterMenuLink = styled(Link)`
  text-decoration: none;
`;

const FooterMenuList = styled.li`
  list-style: none;
  color: #fff;
`;


