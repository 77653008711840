import { Switch, Route } from "react-router-dom";
import Area from "../pages/Area";
import { Comingsoon } from "../pages/Comingsoon";
import Price from "../pages/Price";
import Service from "../pages/Service";
import Top from "../pages/Top";
import ScrollToTop from "../componets/ScrollToTop";
import { Page404 } from "../pages/Page404";
import { Contact } from "../pages/Contact";
// import { Renewal } from "../pages/Renewal";

export const Router = () => {
  return (
    <>
      <ScrollToTop />
      <Switch>
        <Route exact path="/">
          <Top />
        </Route>
        <Route path="/service">
          <Service />
        </Route>
        <Route path="/price">
          <Price />
        </Route>
        <Route path="/area">
          <Area />
        </Route>
        <Route path="/comingsoon">
          <Comingsoon />
        </Route>
        <Route path="/contact">
          <Contact />
        </Route>
        <Route path="*">
          <Page404 />
        </Route>
      </Switch>
    </>
  );
};
