import styled from "styled-components";
import { Title } from "../title/Title";
import ContactImg from "../../images/contact-bg.jpg";

export const TopContact = () => {
  return(
    <SContactBg>
      <SContactMask>
        <Title title="お問い合わせ" />
        <SContactText>
          <SMain><span>民泊に関することなら</span>お気軽にご相談ください。</SMain>
          <SSub>
            <span>民泊を始めたいけれど</span>どうしていいかわからない。<br />
            <span>そんな時こそ私たちの出番です。</span>お気軽にご連絡ください。
            </SSub>
          <SButtons>
            <SButtonList>
              <SButtonLink href="https://line">お問い合わせする</SButtonLink>
            </SButtonList>
            <SButtonList>
              <SButtonLink href="https://line">ああああああ</SButtonLink>
            </SButtonList>
          </SButtons>
        </SContactText>
      </SContactMask>
    </SContactBg>
  );
}

const SContactBg = styled.div`
  background-image: url(${ContactImg});
  height: auto;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  margin-bottom: 80px;
`;

const SContactMask = styled.div`
  background-color: rgba(0, 113, 188, 50%);
  height: 100%;
`;

const SContactText = styled.div`
  color: white;
  text-align: center;
`;

const SMain = styled.p`

`;

const SSub = styled.p`

`;

const SButtons = styled.ul`
  display: flex;
  justify-content: center;
`;

const SButtonList = styled.li`
  width: 274px;
  list-style: none;
  margin: 0 5px;
`;

const SButtonLink = styled.a`
  text-decoration: none;
  display: block;
  position: relative;
  margin: 30px auto;
  padding: 15px;
  width: 274px;
  font-size: 15px;
  font-weight: bold;
  text-align: center;
  background-color: white;
  border-radius: 99px;
  color: #0071BC;
  border: 1px solid #0071BC;
`;