import styled from "styled-components";

export const AreaBlock = ( props ) => {
  const { title, detail } = props;
  return(
    <SAareaBlock>
      <SAreaTitle>
        {title}
      </SAreaTitle>
      <SAreaDetail>
        {detail}
      </SAreaDetail>
    </SAareaBlock>
  );
}

const SAareaBlock = styled.div`
  margin: 40px 0;
`;

const SAreaTitle = styled.div`
  border-left: solid 7px #0890CD;
  padding: 0px 20px;
  font-size: 2em;
  font-weight: bold;
  margin-bottom: 30px;
`;

const SAreaDetail = styled.p`
  font-size: 1.5em;
`;

