import React from "react";
import styled from "styled-components";
import { Layout } from "../layout/Layout";
import { PageTitle } from "../componets/title/PageTitle";
import TopImg from "../images/top-img.jpg";
import { AreaBlock } from "../componets/area/AreaBlock"
import { Container } from "../componets/Container";


const Area = () => {
  return(
    <Layout>
      <PageTitle image={TopImg} title="対応エリア"></PageTitle>
      <Container>
        <AreaBody>
          <AreaBlock title="市郡" detail="那覇市・宜野湾市・浦添市・名護市・糸満市・沖縄市・豊見城市・うるま市・南城市"></AreaBlock>
          <AreaBlock title="中頭郡" detail="読谷村・嘉手納町・北谷町・北中城村・中城村・西原町・島尻郡"></AreaBlock>
          <AreaBlock title="国頭郡" detail="国頭村・大宜味村・東村・今帰仁村・本部町・恩納村・宜野座村・金武町"></AreaBlock>
          <AreaBlock title="島尻郡" detail="与那原町・南風原町・八重瀬町"></AreaBlock>
        </AreaBody>
      </Container>
    </Layout>
  );
}

const AreaBody= styled.div`
  padding-bottom: 40px
`;

export default Area;