import styled from "styled-components";
import { Link } from "react-router-dom";

export const HamburgerMenu = () => {
  const Menus = [
    {name: "トップ", path: "/"},
    {name: "対応地域", path: "/area"},
    {name: "料金詳細", path: "/price"},
    {name: "サービス", path: "comingsoon"},
    {name: "お問い合わせ", path: "/contact"}
  ]
  return(
    <MainMenu>
      <ul>
        {Menus.map((menu, index) => {
          return(
            <SLink key={index} to={menu.path}><Lists kye={index}>{menu.name}</Lists></SLink>
          );
        })}
      </ul>
    </MainMenu>
  );
}

const MainMenu = styled.div`
  background-color: black;
  position: fixed;
  top: 0;
  left:0;
  height: 100vh;
  width: 100%;
  z-index: 99;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;


const SLink = styled(Link)`
  text-decoration: none;
`;

const Lists = styled.li`
  list-style: none;
  line-height: 3;
  color: #fff;
`;