import React, { useState, useEffect } from 'react';
import styled from "styled-components";
import Logo from "../images/Logo.png";

export const Start = () => {
    const [isFirst, setIsFirst] = useState(false);
    const [isSecound, setIsSecound] = useState(false);
    const [isThird, setIsThird] = useState(false);
    const [isActive, setIsActive] = useState(true);

    useEffect(() => {
        setIsFirst(true);
        setTimeout(() => { setIsSecound(true) }, 1000)
        setTimeout(() => { setIsThird(true) }, 2500)
        setTimeout(() => { setIsActive(false) }, 3500)
      }, []);
    return(
        <BackGround isActive={isActive} isThird={isThird}>
            <Move isFirst={isFirst} isSecound={isSecound} />
            <CenterLogo src={Logo} />
        </BackGround>
    );
}

const BackGround = styled.header`
  background: #FFF;
  position: fixed;
  top: 0;
  left: 0;
  height:  100%;
  width: 100%;
  z-index: 9999;
  transition: 1s;
  opacity: ${props => props.isThird ? 0 : 1};
  display: ${props => props.isActive ? 'flex' : 'none'};
  justify-content: center;
  align-items: center;
`;

const Move = styled.div`
    transition: 1s;
    height: ${props => props.isSecound ? '100%' : '10px'};
    width: ${props => props.isFirst ? '100%' : '0px'};
    background-color: whitesmoke;
`;

const CenterLogo = styled.img`
    position: absolute;top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    width: 30%;
`;