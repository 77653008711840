import styled from "styled-components";

export const MainButton = ( props ) => {
  const { children, onClickLink } = props;
  return(
    <SButton onClick={onClickLink}>{children}</SButton> 
  );
};

const SButton = styled.button`
  background-color: #4169e1;
  padding: 14px 30px;
  border-radius: 10px;
  margin: 8px;
  border: none;
  out-line: none;
  cursor: pointer;
  color: white;
  &:hover {
    opacity: 0.8;
  }
`;