import styled from "styled-components";

export const MinusButton = ( props ) => {
  const { children, onClickFaq } = props;
  return(
    <SMinusButton onClick={onClickFaq}>{children}</SMinusButton>
  );
}

const SMinusButton = styled.button`
  border: none;
  outline: none;
  background: none;
`;