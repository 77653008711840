import React from "react";
import styled from "styled-components";
import { Header } from "../componets/header/Header";
import { Image } from "../componets/images/Image";
import { Title } from "../componets/title/Title";
import TopImg from "../images/top-img.jpg";
import TopImg1 from "../images/top-img1.jpg";
import TopImg2 from "../images/top-img2.jpg";
import { Layout } from "../layout/Layout";
import { Text } from "../componets/text/Text";
import { MainButton } from "../componets/button/MainButton";
import { useHistory } from "react-router-dom";
import { TopService } from "../componets/top/TopService";
import { TopPrice } from "../componets/top/TopPrice";
import { TopVoice } from "../componets/top/TopVoice";
import { TopFaq } from "../componets/top/TopFaq";
import { Start } from "../componets/Start";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper.min.css";
import '../App.scss';
import "swiper/components/effect-fade/effect-fade.min.css"
import SwiperCore, { Autoplay, EffectFade } from "swiper/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import mediaQuery from "styled-media-query";
import { TopContact } from "../componets/top/TopContact";


const Top = () => {

  const history = useHistory();

  const onClickPage = () => history.push("/area");

  const onClickService = () => history.push("/comingsoon");

  SwiperCore.use([Autoplay, EffectFade]);


  return(
    <Layout>
      <Start />
      <TopContainer>
        <Swiper 
          loop={true}
          speed={2000}
          autoplay={{ "delay": 4000, disableOnInteraction: false }}
          effect={'fade'}
        >
          <SwiperSlide>
            <Image image={TopImg1} />
          </SwiperSlide>
          <SwiperSlide>
            <Image image={TopImg} />
          </SwiperSlide>
          <SwiperSlide>
            <Image image={TopImg2} />
          </SwiperSlide>
        </Swiper>
        
        <Title title="沖縄の民泊専門会社" />
        <Text>ComingSoon</Text>
        <SButtonContainer>
          <MainButton onClickLink={onClickPage}>対応エリア<FontAwesomeIcon icon={faChevronRight} style={{marginLeft: "15px"}} /></MainButton>
        </SButtonContainer>

        
        <Title title="エアーサプライの掃除サービス" />
        <Text>ComingSoon</Text>
        <TopService />
        <SButtonRight>
          <MainButton onClickLink={onClickService}>詳しくはこちら<FontAwesomeIcon icon={faChevronRight} style={{marginLeft: "15px"}} /></MainButton>
        </SButtonRight>

        <Title title="料金システム" />
        <TopPrice />

        <Title title="お客様の声" />
        <TopVoice />

        <Title title="FAQ" />
        <TopFaq />

        <TopContact />
      </TopContainer>
    </Layout>
  );
}

const SButtonContainer = styled.div`
  text-align: center;
`;

const SButtonRight = styled.div`
  width: 95%;
  text-align: right;
  margin: 20px 0;
  ${mediaQuery.lessThan("medium")`
    text-align: center;
    width: 100%;
  `}
`;

const TopContainer = styled.div`
  background-color: #f8f8ff;
`;





export default Top;