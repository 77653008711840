import React from "react";
import styled from "styled-components";
import { Layout } from "../layout/Layout";
import { PageTitle } from "../componets/title/PageTitle";
import TopImg from "../images/top-img.jpg";
import { PriceRow } from "../componets/price/PriceRow"
import { Container } from "../componets/Container";

const Price = () => {
  return(
    <Layout>
      <PageTitle image={TopImg} title="料金システム"></PageTitle>
      <Container>
        <PriceTable>
          <PriceHeader>
            <PriceHeaderCol>間取り</PriceHeaderCol>
            <PriceHeaderCol>㎡</PriceHeaderCol>
            <PriceHeaderCol>料金（税別）</PriceHeaderCol>
          </PriceHeader>
          <PriceRow floorPlan="1R~" m="~20㎡まで" value="4500円∼" isHeader={false}></PriceRow>
          <PriceRow floorPlan="1R・1K~" m="~30㎡まで" value="5000円∼" isHeader={false}></PriceRow>
          <PriceRow floorPlan="1R~3LDK" m="~50㎡まで" value="6500円∼" isHeader={false}></PriceRow>
          <PriceRow floorPlan="1LDK~4LDK" m="~70㎡まで" value="8000円∼" isHeader={false}></PriceRow>
          <PriceRow floorPlan="2LDK~" m="~90㎡まで" value="9000円∼" isHeader={false}></PriceRow>
          <PriceRow floorPlan="-" m="91㎡~" value="別途ご相談" isHeader={false}></PriceRow>
        </PriceTable>
      </Container>
    </Layout>
  );
}

const PriceTable= styled.div`
  padding: 40px 0 80px 0;
`;

const PriceHeader = styled.div`
  display: flex;
  font-size: 1.2em;
  justify-content: space-around;
`;

const PriceHeaderCol = styled.div`
  width: 33%;
  background-color: #0890CD;
  padding: 20px 0px;
  text-align: center;
  margin-bottom: 3px;
  color: white;
  font-weight:  bold;
`;

export default Price;