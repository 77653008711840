import React from "react";
import { PageTitle } from "../componets/title/PageTitle";
import { Layout } from "../layout/Layout";
import TopImg from "../images/top-img.jpg";

const Service = () => {
  return(
    <Layout>
      <PageTitle image={TopImg} title="サービス" />
    </Layout>
  );
}

export default Service;