import styled from "styled-components";

export const CoolButton = () => {
  return(
      <SCoolButton href="#">
        <SButtonText>お問い合わせはこちら</SButtonText>
      </SCoolButton>
  );
}

const SCoolButton = styled.a`
  border: 1px solid #0071bc;
  background-color: #fff;
  text-decoration: none;
  padding: 16px 44px;
  color: #0071bc;
  position: absolute;
  top: 70%;
  left: 50%;
  position: relative;
  display: inline-block;
  transform: translateY(-50%) translateX(-50%);
  -webkit-transform: translateY(-50%) translateX(-50%);
  transition: color .25s ease;
  &:hover {
    color: #fff;
    
    &::before {
      transform: scaleX(1);
      transform-origin: left;
    }
  }
  &::before {
    position: absolute;
    top: 0;
    left: 0;
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    background-color: #0071bc;
    transform: scaleX(0);
    transform-origin: right;
    transition: all .25s ease;
    transition-property: transform;
  }
}
`;

const SButtonText = styled.span`
  position: relative;
`;