import { Footer } from "../componets/footer/Footer";
import { Header } from "../componets/header/Header";

export const Layout = ( props ) => {
  const { children } = props;
  return(
    <div>
      <Header />
      { children }
      <Footer />
    </div>
  );
}