import { Container, Col } from "react-bootstrap";
import styled from "styled-components";
import AddressIcon from "../../images/footer-icon-address.JPG";
import TelIcon from "../../images/footer-icon-tel.JPG";
import MailIcon from "../../images/footer-icon-mail.JPG";
import mediaQuery from "styled-media-query";

export const FooterContact = () => {
  return(
    <Container>
      <FooterCotact>
        <SRow>
          <Col sm={6}>
            <IconFlex>
              <IconCover>
                <img src={AddressIcon} alt="footer-icon" style={{ height: "30px", width: "30px"}} />
              </IconCover>
              <p>所在地:<br />〒111-2222 東京都港区六本木1-2-3</p>
            </IconFlex>
          </Col>
          <SCol>
            <BoxFlex>
              <Col>
                <IconFlex>
                  <IconCover>
                    <img src={TelIcon} alt="footer-icon" style={{ height: "30px", width: "30px"}} />
                  </IconCover>
                  <p>電話番号:<br />090-3414-9339</p>
                </IconFlex>
              </Col>
              <Col>
                <IconFlex>
                  <IconCover>
                    <img src={MailIcon} alt="footer-icon" style={{ height: "30px", width: "30px"}} />
                  </IconCover>
                  <p>メール:<br />aaaa@test.com</p>
                </IconFlex>
              </Col>
            </BoxFlex>
          </SCol>
        </SRow>
      </FooterCotact>
    </Container>
  );
};

const SRow = styled.div`
  display: flex;
  ${mediaQuery.lessThan("medium")`
    flex-wrap: wrap;
    flex-direction: column;
    align-items: center;
  `}
`;

const SCol = styled.div`
  width: 50%;
  ${mediaQuery.lessThan("medium")`
    width:100%
  `}
`;

const FooterCotact = styled.div`
  background-color: orange;
  border-radius: 10px;
  width: 80%;
  margin: 0 auto;
  position: absolute;
  top: -50px;
  left: 10%;
  padding: 20px;
`;

const IconFlex = styled.div`
  display: flex;
`;

const BoxFlex = styled(IconFlex)`
  ${mediaQuery.lessThan("medium")`
    display: block;
  `}
`;

const IconCover = styled.div`
  border-radius: 50%;
  height: 50px;
  width: 50px;
  border: 2px solid #000;
  text-align: center;
  line-height: 40px;
  margin-right: 10px;
`;