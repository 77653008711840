import styled from "styled-components";
import mediaQuery from "styled-media-query";

export const ChangeImage = ( props ) => {
  const { image, alt } = props;
  return(
    <Img src={image} alt={alt} />
  );
}

const Img = styled.img`
  width: 200px;
  height: 120px;
  ${mediaQuery.lessThan("medium")`
    height: 100px;
    width: 150px;
  `}
`;